import React from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

const StepDiv = styled.h1(({ isFirst }) => [
  tw`flex relative pb-20 sm:items-center md:w-2/3 mx-auto w-full`,
  isFirst ? tw`pt-10` : '',
])

const StepLineContainer = tw.div`h-full w-6 absolute inset-0 flex items-center justify-center`
const StepLine = tw.div`h-full w-1 bg-gray-200 pointer-events-none`
const StepDot = tw.div`flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-gray-300 text-white relative z-10 font-medium text-sm`
const StepMainContainer = tw.div`flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row`
const LogoContainer = tw.div`flex-shrink-0 w-24 h-24 bg-blue-100 text-green-500 rounded-full inline-flex items-center justify-center`
const StepTextContainer = tw.div`flex-grow sm:pl-6 mt-6 sm:mt-0`
const StepMainText = tw.h2`font-medium text-green-500 mb-1 text-xl`

export function Step({ title, description, Logo, isFirst = false }) {
  return (
    <StepDiv isFirst={isFirst}>
      <StepLineContainer>
        <StepLine />
      </StepLineContainer>
      <StepDot />
      <StepMainContainer>
        <LogoContainer>{Logo}</LogoContainer>
        <StepTextContainer>
          <StepMainText>{title}</StepMainText>
          <p className="leading-relaxed">{description}</p>
        </StepTextContainer>
      </StepMainContainer>
    </StepDiv>
  )
}

{
  /*<div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto w-full">*/
}
{
  /*    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">*/
}
{
  /*        <div className="h-full w-1 bg-gray-200 pointer-events-none"/>*/
}
{
  /*    </div>*/
}
{
  /*    <div*/
}
{
  /*        className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10  font-medium text-sm"/>*/
}
{
  /*    <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">*/
}
{
  /*        <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">*/
}
{
  /*            <svg*/
}
{
  /*                stroke="currentColor"*/
}
{
  /*                xmlns="http://www.w3.org/2000/svg"*/
}
{
  /*                viewBox="0 0 512 512"*/
}
{
  /*                className={"fill-current h-12 w-12 text-indigo-500"}*/
}
{
  /*            >*/
}
{
  /*                <path*/
}
{
  /*                    d="M386.689 304.403c-35.587 0-64.538 28.951-64.538 64.538s28.951 64.538 64.538 64.538c35.593 0 64.538-28.951 64.538-64.538s-28.951-64.538-64.538-64.538zm0 96.807c-17.796 0-32.269-14.473-32.269-32.269s14.473-32.269 32.269-32.269 32.269 14.473 32.269 32.269c0 17.797-14.473 32.269-32.269 32.269zM166.185 304.403c-35.587 0-64.538 28.951-64.538 64.538s28.951 64.538 64.538 64.538 64.538-28.951 64.538-64.538-28.951-64.538-64.538-64.538zm0 96.807c-17.796 0-32.269-14.473-32.269-32.269s14.473-32.269 32.269-32.269c17.791 0 32.269 14.473 32.269 32.269 0 17.797-14.473 32.269-32.269 32.269zM430.15 119.675a16.143 16.143 0 00-14.419-8.885h-84.975v32.269h75.025l43.934 87.384 28.838-14.5-48.403-96.268z"/>*/
}
{
  /*                <path*/
}
{
  /*                    d="M216.202 353.345h122.084v32.269H216.202zM117.781 353.345H61.849c-8.912 0-16.134 7.223-16.134 16.134 0 8.912 7.223 16.134 16.134 16.134h55.933c8.912 0 16.134-7.223 16.134-16.134 0-8.912-7.223-16.134-16.135-16.134zM508.612 254.709l-31.736-40.874a16.112 16.112 0 00-12.741-6.239H346.891V94.655c0-8.912-7.223-16.134-16.134-16.134H61.849c-8.912 0-16.134 7.223-16.134 16.134s7.223 16.134 16.134 16.134h252.773V223.73c0 8.912 7.223 16.134 16.134 16.134h125.478l23.497 30.268v83.211h-44.639c-8.912 0-16.134 7.223-16.134 16.134 0 8.912 7.223 16.134 16.134 16.134h60.773c8.912 0 16.134-7.223 16.135-16.134V264.605c0-3.582-1.194-7.067-3.388-9.896zM116.706 271.597H42.487c-8.912 0-16.134 7.223-16.134 16.134 0 8.912 7.223 16.134 16.134 16.134h74.218c8.912 0 16.134-7.223 16.134-16.134.001-8.911-7.222-16.134-16.133-16.134zM153.815 208.134H16.134C7.223 208.134 0 215.357 0 224.269s7.223 16.134 16.134 16.134h137.681c8.912 0 16.134-7.223 16.134-16.134s-7.222-16.135-16.134-16.135z"/>*/
}
{
  /*                <path*/
}
{
  /*                    d="M180.168 144.672H42.487c-8.912 0-16.134 7.223-16.134 16.134 0 8.912 7.223 16.134 16.134 16.134h137.681c8.912 0 16.134-7.223 16.134-16.134.001-8.911-7.222-16.134-16.134-16.134z"/>*/
}
{
  /*            </svg>*/
}
{
  /*        </div>*/
}
{
  /*        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">*/
}
{
  /*            <h2 className="font-medium  text-gray-900 mb-1 text-xl">*/
}
{
  /*                Streamlined Shipping*/
}
{
  /*            </h2>*/
}
{
  /*            <p className="leading-relaxed">*/
}
{
  /*                Delivery on time.*/
}
{
  /*                <span className={"font-bold"}> Everytime.</span>*/
}
{
  /*            </p>*/
}
{
  /*        </div>*/
}
{
  /*    </div>*/
}
{
  /*</div>*/
}
