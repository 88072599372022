// import React from "react";
// import styled from "styled-components"; //eslint-disable-line
// import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
// // import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
// import MarketImage from "../../../images/masala-4096891_1280.jpg";
//
// const Container = tw.div`relative`;
// const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24`;
// const Row = tw.div`max-w-screen-xl mx-auto flex flex-wrap z-10 flex-col lg:flex-row text-center lg:text-left items-stretch rounded-2xl shadow-2xl bg-green-700`;
//
// const ColumnContainer = tw.div`lg:w-3/5 md:w-1/2 pl-12 flex flex-col self-center`;
// const TextContainer = tw(ColumnContainer)`h-auto`;
//
// const ImageContainer = tw.div`lg:w-2/5 md:w-1/2`;
//
// const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold whitespace-pre-line`;
// //
// // const LinksContainer = tw(
// //   ColumnContainer
// // )`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;
// //
// // const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
// // const PrimaryLink = tw(
// //   Link
// // )`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;
// //
// // const SecondaryLink = tw(
// //   Link
// // )`text-gray-100 border-gray-500 hover:bg-gray-100 hover:text-green-500 hover:border-green-500`;
//
// export const CallToAction = ({
//   text = "Developers all over the world are happily using Treact.",
//   // primaryLinkText = "Get Started",
//   // primaryLinkUrl = "http://timerse.com",
//   // secondaryLinkText = "Contact Us",
//   // secondaryLinkUrl = "http://google.com",
//   pushDownFooter = true,
//   // secondaryLink,
// }) => {
//   return (
//     <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
//       {/*<ContentWithPaddingXl>*/}
//       <PrimaryBackgroundContainer>
//         <Row>
//           <TextContainer>
//             <Text>{text}</Text>
//             {/*<div className={"block"}>*/}
//             {/*  <a href={"asd"} className={"bg-white text-green-500 p-6"}>*/}
//             {/*    Contact Us*/}
//             {/*  </a>*/}
//             {/*</div>*/}
//           </TextContainer>
//           {/*<LinksContainer>*/}
//           {/*  <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>*/}
//           {/*  {secondaryLink && (*/}
//           {/*    <SecondaryLink href={secondaryLinkUrl}>*/}
//           {/*      {secondaryLinkText}*/}
//           {/*    </SecondaryLink>*/}
//           {/*  )}*/}
//           {/*</LinksContainer>*/}
//           <ImageContainer>
//             <img
//               src={MarketImage}
//               alt={"marketimage"}
//               tw={"w-144 rounded-r-2xl"}
//             />
//           </ImageContainer>
//         </Row>
//       </PrimaryBackgroundContainer>
//       {/*</ContentWithPaddingXl>*/}
//     </Container>
//   );
// };

import React from 'react'
import styled from 'styled-components' //eslint-disable-line
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from '../../../images/svg-decorator-blob-9.svg'
// import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import { Link } from 'gatsby'

const Container = tw.div`relative`
const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-green-500 rounded-t-lg relative`
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)``
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold whitespace-pre-line`

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`

const InternalLink = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-8 lg:py-4 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`
const PrimaryLink = tw(
  InternalLink
)`bg-white text-green-500 shadow-lg hocus:bg-gray-300 hocus:text-green-700`

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-t-lg`
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-72 h-72 transform -translate-x-20 translate-y-32 text-green-700 opacity-25`
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-72 h-72 transform  translate-x-20 -translate-y-64 text-green-700 opacity-25`

export const CallToAction = ({
  text = 'Developers all over the world are happily using Treact.',
  primaryLinkText = 'Get Started',
  primaryLinkUrl = 'http://timerse.com',
  pushDownFooter = true,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      {/*<ContentWithPaddingXl>*/}
      <PrimaryBackgroundContainer>
        <Row>
          <TextContainer>
            <Text>{text}</Text>
          </TextContainer>
          <LinksContainer>
            <PrimaryLink as={Link} to={primaryLinkUrl}>
              {primaryLinkText}
            </PrimaryLink>
          </LinksContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </DecoratorBlobContainer>
      </PrimaryBackgroundContainer>
      {/*</ContentWithPaddingXl>*/}
    </Container>
  )
}
