import * as constants from '../consts/index'

import { CallToAction } from '../components/components/cta/GetStarted'
import DeliveryLogo from '../images/delivery-logo.svg'
import { Hero } from '../components/components/hero/TwoColumnWithVideoForContact'
import Layout from '../components/layout'
import ProductsLogo from '../images/products-icon.svg'
import QualityLogo from '../images/quality-logo.svg'
import React from 'react'
import { Step } from '../components/step'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import SEO from '../components/seo'

const HighlightedText = tw.span`bg-green-500 text-gray-100 px-4 py-4 transform -skew-x-12 inline-block`
const HeaderText = tw.h2`text-3xl sm:text-5xl font-bold tracking-tight`
const ParagraphText = tw.p`text-gray-700 mt-4 font-medium`

const WholesalerSection = tw.section`text-gray-700`
const WholesalerSectionContainer = tw.div`max-w-3xl px-5 py-12 mx-auto flex flex-wrap`
const WholesalerTextContainer = tw.div`pb-12`

function WholesalePage({ data }) {
  return (
    <Layout
      bgColor={constants.bgColor}
      isDark={constants.isDark}
      Hero={
        <Hero
          bgColor={constants.bgColor}
          heading={
            <>
              Welcome <span className={'text-green-500'}>Distributors.</span>
            </>
          }
          description={
            'Interested in bringing your store alive with our unique Sinaran Rempah Asli flavours?'
          }
          isDark={constants.isDark}
          imageCss={'rounded-2xl shadow-xl z-10'}
          imageSrc={data.file.childImageSharp.fluid}
          imageDecoratorBlob={false}
        />
      }
    >
      <SEO
        keywords={[
          `supplier`,
          `spice`,
          `distributor`,
          `spices distributor`,
          `distribute spices`,
          `sinaran rempah`,
          `spices`,
          `spices malaysia`,
        ]}
        title="Be our Distributor"
      />
      <WholesalerSection>
        <WholesalerSectionContainer>
          <WholesalerTextContainer>
            <HeaderText>
              Become a Sinaran Rempah Asli{' '}
              <HighlightedText>Distributor</HighlightedText>{' '}
            </HeaderText>
            <ParagraphText>
              We have a wide range of products, and we want to have our products
              in the finest stores in the country. You can be a part of our team
              and become our distributor. We have made it easy and profitable to
              carry our products.
            </ParagraphText>
          </WholesalerTextContainer>
          <Step
            title={'Streamlined Shipping'}
            description={
              'We make sure that your delivery is on time everytime.'
            }
            Logo={
              <img
                className={'fill-current h-12 w-12 text-green-500'}
                src={DeliveryLogo}
              />
            }
            isFirst={true}
          />
          <Step
            title={'Quality Products'}
            description={
              'We offer you the best quality products to ensure that the customers want more.'
            }
            Logo={
              <img
                className={'fill-current h-12 w-12 text-green-500'}
                src={QualityLogo}
              />
            }
          />
          <Step
            title={'Wide Variety'}
            description={
              'We have several spices in our range, so there are lots of options'
            }
            Logo={
              <img
                className={'fill-current h-12' + ' w-12 text-green-500'}
                src={ProductsLogo}
              />
            }
          />
        </WholesalerSectionContainer>
      </WholesalerSection>
      <CallToAction
        secondaryLink={false}
        pushDownFooter={false}
        primaryLinkText={'Contact Us'}
        text={`Interested?\nContact us for more details!`}
        primaryLinkUrl={'/contact'}
      />
    </Layout>
  )
}

export default WholesalePage

export const query = graphql`
  query {
    file(relativePath: { eq: "food-factories-905512_1920.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920, maxHeight: 432) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
